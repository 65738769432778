import PropTypes from 'prop-types';
import Slider from 'react-slick';
// @mui
import { Box, Card } from '@mui/material';
// components
import Image from 'next/image';
import { CarouselDots } from '../../components/carousel';

// ----------------------------------------------------------------------
const data = [
  {
    id: 0,
    image: '/slider/on-thi-bang-lai-xe.jpg',
  },
  {
    id: 1,
    image: '/slider/nhan-ho-so-3.png',
  },
  {
    id: 2,
    image: '/slider/lo-trinh-hoc-xe-oto.jpg',
  },
  {
    id: 3,
    image: '/slider/nhan-ho-so-2.png',
  },
  {
    id: 4,
    image: '/slider/lo-trinh-hoc-xe-may.jpg',
  },
  {
    id: 5,
    image: '/slider/mo-phong.jpg',
  },
  {
    id: 6,
    image: '/slider/nhan-ho-so.png',
  },
];

// ----------------------------------------------------------------------

export default function HomeSlider() {
  const settings = {
    speed: 1500,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...CarouselDots({ position: 'absolute', right: 24, bottom: 24 }),
  };

  return (
    <Card>
      <Slider {...settings}>
        {data.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Slider>
    </Card>
  );
}

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
  }),
};

function CarouselItem({ item }) {
  const { image } = item;

  return (
    <Box sx={{ position: 'relative' }}>
      <Image alt={"OnThiBangLaiXe.net"} src={image} height={380} width={380 * 16 / 8} sizes='cover' priority />
    </Box>
  );
}
